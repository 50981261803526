import * as React from "react"

import { Link, graphql } from "gatsby"

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../../components/Layout"
import LineItem from "../../components/store/LineItem"
import ProductListingCarousel from "../../components/store/ProductListingCarousel"
import Seo from "../../components/seo"
import { StoreContext } from "../../context/store-context"
import StoreFooter from "../../components/StoreFooter"
import { formatPrice } from "../../utils/format-price"

export const query = graphql`
query {
  allShopifyProduct(limit: 10, sort: {fields: createdAt, order: DESC}) {
    nodes {
			...ProductCard
    }
  }
}
`;

export default function CartPage({data, pageContext}) {
  const { checkout, initalizing, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0;
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const handleCheckout = () => {
    window.location = checkout.webUrl;
  };

  return (
    <Layout>
      <Seo title="Cart | Store" />
      <div className="bg-dark-night p-5 lg:p-12 lg:pb-8">
        <div className="max-w-1920 mx-auto">
          <h1 className="page-heading sr-only">Cart</h1>
          <div className="mb-10 lg:mb-20"><Breadcrumb crumbs={crumbs} crumbLabel="Cart" crumbSeparator="»" /></div>

          {initalizing ?
            <div className="my-60 text-center">
              <h2 className="text-4xl md:text-5xl mb-4">Loading...</h2>
            </div>
            :
            <>
            {emptyCart ? (
              <div className="">
                <div className="my-60 text-center">
                  <h2 className="text-4xl md:text-5xl mb-4">Your cart is empty <span className="ml-1" aria-label="pleading face" role="img">🥺</span></h2>
                  <Link to="/store">View Store</Link>
                </div>
    
                <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold w-full text-gray-475 mb-6 font-serif">Checkout the latest products</h2>
                <ProductListingCarousel products={data?.allShopifyProduct?.nodes} limit={10} />
              </div>
            ) : (
              <div className="">
                <table className="cart-table">
                  <thead>
                    <tr>
                      <th className="">Product</th>
                      <th className="">Price</th>
                      <th>Qty</th>
                      <th className="">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkout.lineItems.map((item) => (
                      <LineItem item={item} key={item.id} />
                    ))}
                  </tbody>
                </table>
    
                <div className="flex justify-center md:justify-end">
                  <table className="text-right">
                  <tbody>
                      <tr className="">
                        <td className="pr-4 py-1">Subtotal</td>
                        <td className="py-1">
                          {formatPrice(
                            checkout.subtotalPriceV2.currencyCode,
                            checkout.subtotalPriceV2.amount
                          )}
                        </td>
                      </tr>
                    
                      <tr className="">
                        <td className="pr-4 py-1">Taxes</td>
                        <td className="py-1">
                          {formatPrice(
                            checkout.totalTaxV2.currencyCode,
                            checkout.totalTaxV2.amount
                          )}
                        </td>
                      </tr>
                    
                      <tr className="">
                        <td className="pr-4 py-1">Shipping</td>
                        <td className="text-xs text-gray-450 py-1">Calculated at <br /> checkout</td>
                      </tr>
                    
                      <tr className="text-xl">
                        <td className="pr-4 font-bold py-1">Total Price</td>
                        <td className="text-green-500 py-1">
                          {formatPrice(
                            checkout.totalPriceV2.currencyCode,
                            checkout.totalPriceV2.amount
                          )}
                        </td>
                      </tr>
                    </tbody>
                </table>
                </div>
    
                <div className="flex flex-col items-center md:flex-row-reverse">
                  <button
                    onClick={handleCheckout}
                    disabled={loading}
                    className={`my-6 btn w-full md:w-auto text-2xl`}
                  >
                    Checkout
                  </button>
    
              
                </div>
              </div>
            )}
            </>
          }

          <StoreFooter />
        </div>
      </div>
    </Layout>
  )
}
