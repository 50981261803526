import * as React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
// import DeleteIcon from "../icons/delete"
import { Link } from "gatsby"
import NumericInput from "./NumericInput"
import { StoreContext } from "../../context/store-context"
import debounce from "lodash.debounce"
import { formatPrice } from "../../utils/format-price"
import { getShopifyImage } from "gatsby-source-shopify"

const LineItem = ({ item }) => {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading,
  } = React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant?.image?.src,
  }
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    let val = Number(value);

    if (!val || val < 1) {
      val = 1;
    }

    if (val > 99) {
      val = 99;
    }

    setQuantity(val)
    debouncedUli(val)
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  let prod_handle = item?.variant?.product?.handle ? `/store/products/${item.variant.product.handle}` : null;

  

  const image = React.useMemo(
    () => {

      if (variantImage && variantImage.originalSrc) {
          return getShopifyImage({
            image: variantImage,
            layout: "constrained",
            crop: "contain",
            width: 320, // 4
            height: 400, // x 5
          });
      }
      
      return null;
    },
    
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <tr>
      <td>
          <div className="line-item-details">
            <div className="w-24 lg:w-40">
              {image ?
                <>
                  {prod_handle ?
                    <Link to={prod_handle} className="block border-none no-underline">
                      <GatsbyImage
                        className="rounded-lg"
                        key={variantImage.src}
                        image={image}
                        alt={variantImage.altText ?? item.variant.title}
                      />
                    </Link>
                  :
                    <GatsbyImage
                      className="rounded-lg"
                      key={variantImage.src}
                      image={image}
                      alt={variantImage.altText ?? item.variant.title}
                    />
                  }
                </>
                :
                <div className="rounded-lg aspect-w-4 aspect-h-5 bg-black border border-gray-475">
                  <div className="text-gray-450 text-sm h-full w-full flex items-center justify-center">No image</div>
                </div>
              }
            </div>

            <div className="flex-1">
              <h2 className="line-item-title font-bold font-serif text-3xl md:text-2xl lg:text-3xl">
                {prod_handle ?
                  <Link to={prod_handle}>{item.title}</Link>
                  : <>{item.title}</>
                }
              </h2>
              {item.variant.title.toLowerCase() !== "default title" ?
                <div className="text-sm text-gray-400">
                  {item.variant.title}
                </div>
                : null
              }

              <div className="pt-4 flex justify-end md:block">
                <button className="underline text-red-500" onClick={handleRemove}>
                  Remove
                </button>
              </div>
            </div>

            
          </div>
       


        
      </td>
      <td className="">{price}</td>
      <td>
        <NumericInput
          uid={item.id}
          disabled={loading}
          value={quantity}
          aria-label="Quantity"
          onIncrement={doIncrement}
          onDecrement={doDecrement}
          onChange={(e) => handleQuantityChange(e.currentTarget.value)}
        />
      </td>
      <td className="price text-lg">{subtotal}</td>
    </tr>
  )
};

export default LineItem